//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-720:_8258,_5299,_9918,_2880,_4128,_252,_6200,_196;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-720')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-720', "_8258,_5299,_9918,_2880,_4128,_252,_6200,_196");
        }
      }catch (ex) {
        console.error(ex);
      }